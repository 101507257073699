/* section layout */
.hero {
  position: relative;
  overflow: hidden;
  /* height: 100%;*/
  /* max-height: calc(100vh - 54px);*/
  min-height: 45em; }
  .hero h1 {
    margin: 0 auto 1rem; }
  .hero h1,
  .hero h2 {
    width: 120%; }

.lookBeyond {
  font-size: 20px; }

.image-style {
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16); }

.hero-company {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: calc(100vh - 54px);
  padding-bottom: 20px; }
  .hero-company h1 {
    margin: 0 auto 1rem; }
  .hero-company h1,
  .hero-company h2 {
    width: 120%;
    margin-left: -10%; }

.topSection {
  background-color: #f3f3f3; }

.hero-content {
  position: absolute;
  top: 0px;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-content-company {
  position: absolute;
  top: 0px;
  bottom: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-form-container {
  margin-top: -54px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%); }

.heroCol {
  padding: 2rem 0 0 3rem; }

.typed-text {
  color: #838383; }

.title-text {
  font-size: 28px !important;
  line-height: 42px !important; }
  @media (max-width: 575px) {
    .title-text {
      font-size: 22px !important;
      line-height: 28px !important; } }

.how-it-works {
  font-size: 20px !important;
  line-height: 40px !important; }
  @media (max-width: 575px) {
    .how-it-works {
      font-size: 16px !important;
      line-height: 20px !important; } }

.info-card {
  width: 400px; }

.title-left-margin {
  margin-left: 3rem !important; }
  @media (max-width: 575px) {
    .title-left-margin {
      margin-left: 1rem !important; } }

.first-card {
  border-left: 8px solid #2a2497;
  border-radius: 5px;
  -webkit-transition: width 0s;
  /* Safari */
  -webkit-transition-timing-function: linear;
  /* Safari */
  -o-transition: width 0s;
  transition: width 0s;
  -o-transition-timing-function: linear;
     transition-timing-function: linear;
  /* transition-delay: 0s;*/ }

.second-card {
  border-left: 8px solid #48bcae;
  border-radius: 5px;
  -webkit-transition: width 0s;
  /* Safari */
  -webkit-transition-timing-function: linear;
  /* Safari */
  -o-transition: width 0s;
  transition: width 0s;
  -o-transition-timing-function: linear;
     transition-timing-function: linear;
  /* transition-delay: 0s;*/ }

.third-card {
  border-left: 8px solid #f6ae25;
  border-radius: 5px;
  background: white;
  -webkit-transition: width 0s;
  /* Safari */
  -webkit-transition-timing-function: linear;
  /* Safari */
  -o-transition: width 0s;
  transition: width 0s;
  -o-transition-timing-function: linear;
     transition-timing-function: linear;
  /* transition-delay: 0s;*/ }

.margin-top-desktop {
  margin-top: 1em;
  background-color: white;
  /* @include media-breakpoint-down(lg) {*/
  /*   margin-top: 0em;*/
  /* }*/ }

.card-title {
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 0px !important; }

.min-height {
  min-height: 37em; }
