.skill-info-badge {
  width: 7.5rem; }

.parentCell {
  display: table; }

.childCell {
  display: table-cell;
  vertical-align: middle; }

.wordWrap {
  word-break: break-all; }

.fixedWidth {
  width: 70px; }

.title {
  font-size: 25px;
  line-height: 32px; }

.subTitle {
  font-size: 18px;
  line-height: 20px; }

.custom-slider .slick-arrow .slick-next {
  width: 0 !important;
  right: 0 !important; }

.printWidth {
  width: 700px; }

.tableHeader {
  min-width: 2rem; }

.table-tr {
  border-bottom: 0px; }

.report {
  text-align: center;
  margin-top: -13%; }

.niceWordBreak {
  word-break: break-word; }

.border-bottom-gray {
  border-bottom: 1px solid #bdc3c7; }

.all-border {
  border: 1px solid #bdc3c7; }

.small-height {
  height: 50px; }

.questionBox {
  padding: 1rem;
  border: solid 1px #f3f3f3;
  margin-bottom: 2rem;
  font-size: 20px;
  width: 100%; }

.question {
  font-size: 20px;
  margin: 1rem 0; }

.errorNotification {
  font-size: 16px;
  margin-top: 1rem;
  color: red; }

.solution {
  font-size: 16px; }

.primaryTitle {
  font-size: 14px;
  font-weight: bolder;
  background: #131313;
  padding: 4px 0 4px 1rem;
  color: white !important; }

.secondaryTitle {
  font-size: 14px;
  font-weight: bolder;
  background: #e7e7e7;
  padding: 4px 0 4px 1rem; }

.htmlEditor {
  width: 100%; }

.reviewForm {
  font-size: 14px;
  margin-top: 2rem; }
  .reviewForm span {
    font-weight: bolder; }

.userAnswerBox {
  padding: 1rem 8px 8px;
  border-radius: 5px;
  width: 100%;
  background: #f3f3f3;
  margin-bottom: 1.5rem; }

.feedback {
  background: #ffffff;
  padding: 8px; }
  .feedback p {
    font-size: 16px; }
  .feedback div {
    margin: 8px; }
  .feedback .label {
    font-weight: 900; }

.validatorFeedback {
  padding: 1rem 8px 8px;
  margin-top: 1rem;
  border-radius: 5px;
  width: 100%;
  background: #f3f3f3; }

.date {
  font-size: 12px;
  color: blue !important; }

.validator {
  font-size: 16px;
  font-weight: 900; }

.quill-editor div {
  border: none !important;
  padding-top: 0px; }
