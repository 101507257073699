.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0; }
  .footer .footer-container .footer-main {
    display: -ms-flexbox;
    display: flex;
    padding-top: 4rem;
    border-top: 1px solid #7c7b7b; }
    .footer .footer-container .footer-main .footer-column nav ul li {
      margin-bottom: 5px; }
      .footer .footer-container .footer-main .footer-column nav ul li:last-child {
        margin-bottom: 0; }
    .footer .footer-container .footer-main .footer-column.column-logo {
      width: 18.75%;
      margin-right: auto; }
    .footer .footer-container .footer-main .footer-column.column-nav-info {
      width: 31.25;
      margin-right: auto; }
    .footer .footer-container .footer-main .footer-column.column-nav-social {
      width: 31.25;
      margin-right: auto; }
    .footer .footer-container .footer-main .footer-column.column-newsletter .form-item {
      margin-bottom: 0; }
  .footer .footer-container .footer-bottom {
    display: -ms-flexbox;
    display: flex;
    font-size: 14px; }
    .footer .footer-container .footer-bottom .footer-bottom-copyright {
      margin-right: auto; }
    .footer .footer-container .footer-bottom .footer-bottom-nav nav ul li {
      display: inline-block; }
      .footer .footer-container .footer-bottom .footer-bottom-nav nav ul li:after {
        content: '/';
        margin-left: 5px;
        margin-right: 5px;
        display: inline-block; }
      .footer .footer-container .footer-bottom .footer-bottom-nav nav ul li:last-child:after {
        content: '';
        display: none; }

.inverted {
  -webkit-filter: invert(100%);
          filter: invert(100%); }
  .inverted a:hover {
    color: black; }
  .inverted button {
    -webkit-filter: invert(100%);
            filter: invert(100%); }
