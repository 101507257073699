.plan-card {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #b5b5b5;
  border-radius: 2px; }
  .plan-card .title {
    font-family: 'GT America Expanded Medium';
    font-size: 24px;
    letter-spacing: 0;
    line-height: 24px; }
  .plan-card .price {
    font-family: 'GT America Standard Bold';
    font-size: 36px; }
    .plan-card .price span {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 34px; }
  .plan-card .select-button {
    border: 2px solid #000000;
    border-radius: 21px;
    font-size: 14px;
    background-color: #ffffff;
    color: #000000; }
    .plan-card .select-button:hover {
      background-color: #000000;
      color: #ffffff; }
  .plan-card .features {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 25px;
    /*text-align: center;*/
    margin-bottom: 0; }
  .plan-card.premium-card {
    border: 3px solid #0052cc;
    border-radius: 2px; }
    .plan-card.premium-card .title {
      color: #0052cc;
      font-family: 'GT America Expanded Bold'; }
    .plan-card.premium-card .price {
      color: #0052cc; }
      .plan-card.premium-card .price span {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 34px; }
    .plan-card.premium-card .select-button {
      color: #ffffff;
      background-color: #0052cc;
      border: 2px solid #0052cc; }
      .plan-card.premium-card .select-button:hover {
        background-color: #ffffff;
        color: #0052cc; }

.order-card {
  max-width: 24.5em;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #464646;
  border-radius: 10px;
  background-color: #ffffff; }
  .order-card .orderSummary {
    font-size: 20px !important;
    letter-spacing: 0;
    line-height: 25px; }
  .order-card .plan {
    font-size: 16px;
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 900;
    letter-spacing: 0.18px;
    line-height: 22px; }
  .order-card .planType {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 18px; }
  .order-card .amount,
  .order-card .charge {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px; }
  .order-card .amountVal,
  .order-card .chargeVal {
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 18px; }
  .order-card .totalAmount {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0.18px;
    line-height: 22px; }
  .order-card .totalAmountVal {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 18px; }

.plan-box {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #000000;
  border-radius: 2px;
  cursor: pointer;
  opacity: 0.5;
  min-height: 12em; }
  .plan-box h2 {
    font-family: 'GT America Expanded Medium';
    font-size: 24px;
    letter-spacing: 0;
    line-height: 24px; }
  .plan-box .price {
    font-family: 'GT America Standard Bold';
    font-size: 36px;
    letter-spacing: 0;
    line-height: 34px; }
    .plan-box .price span {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 34px;
      font-weight: unset; }
  .plan-box .billed {
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 32px; }
  .plan-box.active {
    border: 2px solid #0052cc;
    opacity: 1; }
    .plan-box.active h2 {
      color: #0052cc; }
    .plan-box.active .price {
      color: #0052cc; }

.cardInput {
  padding: 10px;
  margin-top: 2%; }

.card-details {
  min-width: 220px; }

.bold-text {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.extra-bold-text {
  font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.point-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  /*margin-left: 210px;*/ }

.point {
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px; }

.pricing-card {
  border: 2px solid #b5b5b5; }
  .pricing-card .title .realPrice {
    position: relative; }
    .pricing-card .title .realPrice .superScript {
      font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
      font-size: 1.5rem; }
    .pricing-card .title .realPrice .priceText {
      font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
      font-size: 4rem;
      line-height: 3rem;
      height: 4rem; }
    .pricing-card .title .realPrice .promo {
      position: absolute;
      z-index: 200;
      width: 60px;
      height: 60px;
      background-color: #00b1a4;
      top: -30px;
      right: -30px;
      border-radius: 30px;
      color: white;
      font-size: 12px;
      padding-top: 16px;
      text-align: center; }
  .pricing-card .title .bold-dollar {
    font-size: 28px;
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .pricing-card .title .bold {
    font-size: 4rem;
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .pricing-card .title .strikeThrough {
    position: relative; }
    .pricing-card .title .strikeThrough .dash {
      position: absolute;
      z-index: 200;
      width: 65px;
      height: 2px;
      left: -35%;
      top: 35%;
      background: #ed6630;
      -webkit-transform: rotate(-40.57deg);
          -ms-transform: rotate(-40.57deg);
              transform: rotate(-40.57deg); }
    .pricing-card .title .strikeThrough .superScript {
      position: relative;
      font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
      font-size: 1rem;
      color: #7e7e80; }
    .pricing-card .title .strikeThrough .priceText {
      position: relative;
      font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
      font-size: 3rem;
      line-height: 3rem;
      height: 4rem;
      color: #7e7e80; }
  .pricing-card .highlights {
    /*font-size: 16pt;*/
    letter-spacing: 0;
    line-height: 28px; }
    .pricing-card .highlights .svg {
      width: 14px; }
  .pricing-card .secondary {
    font-size: 18px;
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .pricing-card .feature-box {
    background-color: #eff3fe;
    border-radius: 5px;
    padding: 1rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem 1rem; }
    .pricing-card .feature-box .feature {
      color: #000000;
      font-family: 'GT America Standard Regular', serif;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 32px; }
    .pricing-card .feature-box .bold-text {
      font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 32px; }

.highlights {
  /*font-size: 16pt;*/
  letter-spacing: 0;
  line-height: 28px; }
  .highlights .svg {
    width: 14px; }
