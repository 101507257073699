.mobile-feature-boxes {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #97add0;
  border-radius: 4px; }
  .mobile-feature-boxes p {
    font-size: 16px;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    /*padding: 0.3rem 0;*/ }
  .mobile-feature-boxes .heading {
    font-size: 24px !important;
    font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    /*padding: 0.3rem 0;*/ }
  .mobile-feature-boxes .mute {
    color: #888888; }

.mobile-premium-box {
  border: 2px solid #2a2498;
  color: #2a2498 !important; }
  .mobile-premium-box .header {
    background: #2a2498;
    /*border-radius: 4px 4px 0 0;*/
    color: white; }
  .mobile-premium-box .mute {
    color: rgba(42, 36, 152, 0.53); }

.pricingTableDesktop .bold,
.pricingTableMobile .bold {
  font-family: 'GT America Expanded Bold', 'Helvetica Neue', Helvetica, Arial, serif;
  color: #2a2498; }

.pricingTableDesktop .border-bottom,
.pricingTableMobile .border-bottom {
  border-bottom: 1px solid #dbe4f3; }

.pricingTableDesktop .emptyCell,
.pricingTableMobile .emptyCell {
  height: 30px; }

.pricingTableDesktop .abandonedCell,
.pricingTableMobile .abandonedCell {
  height: 100px; }

.pricingTableDesktop .halfAbandonedCell,
.pricingTableMobile .halfAbandonedCell {
  height: 50px; }

.pricingTableDesktop .perMonth,
.pricingTableMobile .perMonth {
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, serif;
  font-size: 14px;
  color: #2a2498; }

.pricingTableDesktop .standardPriceCellMobile,
.pricingTableMobile .standardPriceCellMobile {
  /* height: 260px !important;*/
  padding-bottom: 1rem; }

.pricingTableDesktop .standardPriceCell,
.pricingTableMobile .standardPriceCell {
  position: relative;
  height: 300px; }
  .pricingTableDesktop .standardPriceCell .promo,
  .pricingTableMobile .standardPriceCell .promo {
    position: absolute;
    z-index: 200;
    width: 70px;
    height: 70px;
    background-color: #00b1a4;
    top: -35px;
    right: -35px;
    border-radius: 35px;
    color: white;
    font-size: 14px;
    padding-top: 18px;
    text-align: center; }
  .pricingTableDesktop .standardPriceCell .superScript,
  .pricingTableMobile .standardPriceCell .superScript {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
    font-size: 1.5rem;
    color: #2a2498; }
  .pricingTableDesktop .standardPriceCell .priceText,
  .pricingTableMobile .standardPriceCell .priceText {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
    font-size: 4rem;
    line-height: 3rem;
    height: 4rem;
    color: #2a2498; }
  .pricingTableDesktop .standardPriceCell .strikeThrough,
  .pricingTableMobile .standardPriceCell .strikeThrough {
    position: relative; }
    .pricingTableDesktop .standardPriceCell .strikeThrough .dash,
    .pricingTableMobile .standardPriceCell .strikeThrough .dash {
      position: absolute;
      z-index: 200;
      width: 65px;
      height: 2px;
      left: -35%;
      top: 35%;
      background: #ed6630;
      -webkit-transform: rotate(-40.57deg);
          -ms-transform: rotate(-40.57deg);
              transform: rotate(-40.57deg); }
    .pricingTableDesktop .standardPriceCell .strikeThrough .superScript,
    .pricingTableMobile .standardPriceCell .strikeThrough .superScript {
      position: relative;
      font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
      font-size: 1rem;
      color: #7e7e80; }
    .pricingTableDesktop .standardPriceCell .strikeThrough .priceText,
    .pricingTableMobile .standardPriceCell .strikeThrough .priceText {
      position: relative;
      font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
      font-size: 3rem;
      line-height: 3rem;
      height: 4rem;
      color: #7e7e80; }

@media (max-width: 575px) {
  .pricingTableDesktop .standardPriceCell,
  .pricingTableMobile .standardPriceCell {
    height: auto; } }

.pricingTableDesktop .enterprisePriceCell,
.pricingTableMobile .enterprisePriceCell {
  height: 200px;
  text-align: center;
  padding-top: 2.4rem; }

.pricingTableDesktop .enterprisePriceCellMobile,
.pricingTableMobile .enterprisePriceCellMobile {
  /* height: 150px;*/
  text-align: center;
  padding-top: 2.4rem;
  padding-bottom: 1rem; }

.pricingTableDesktop .enterpriseBox,
.pricingTableMobile .enterpriseBox {
  border-top: 1px solid #dbe4f3;
  border-right: 1px solid #dbe4f3;
  border-bottom: 1px solid #dbe4f3; }

.pricingTableDesktop .enterpriseBoxMobile,
.pricingTableMobile .enterpriseBoxMobile {
  border: 1px solid #dbe4f3; }

.pricingTableDesktop .standardCell,
.pricingTableMobile .standardCell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  border-top: 1px solid white;
  font-family: 'GT America Standard Bold', 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, serif;
  color: #2a2498; }

.pricingTableDesktop .standardCellMobile,
.pricingTableMobile .standardCellMobile {
  height: 50px !important;
  border: none !important; }

.pricingTableDesktop .featureCell,
.pricingTableMobile .featureCell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  border-top: 1px solid #dbe4f3;
  font-family: 'GT America Standard Bold', 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, serif; }

.pricingTableDesktop .text-center,
.pricingTableMobile .text-center {
  -ms-flex-pack: center;
      justify-content: center; }

.pricingTableDesktop .padded,
.pricingTableMobile .padded {
  padding-left: 2.5rem; }

.pricingTableDesktop .coloredCell,
.pricingTableMobile .coloredCell {
  background-color: #edf4ff; }

.pricingTable {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: calc(100vh - 54px); }
  .pricingTable h1 {
    margin: 0 auto 1rem; }
  .pricingTable h1,
  .pricingTable h2 {
    width: 100%; }

.lookBeyond {
  font-size: 20px; }

.image-style {
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16); }

.topSection {
  background-color: #f3f3f3; }

.pricingTable-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.pricingTable-content-company-mobile {
  position: absolute;
  top: 0px;
  bottom: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.pricingTable-form-container {
  margin-top: -54px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.pricingTable-scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%); }

.pricingTableCol {
  padding: 2rem 0 0 3rem; }

.typed-text {
  color: #838383; }

.subtitle-text {
  font-size: 20px !important;
  line-height: 26px !important; }
  @media (max-width: 575px) {
    .subtitle-text {
      font-size: 18px !important;
      line-height: 28px !important; } }

.title-left-margin {
  margin-left: 3rem !important; }
  @media (max-width: 575px) {
    .title-left-margin {
      margin-left: 0.25rem !important; } }

.margin-top-desktop {
  margin-top: 11em; }

.shapes {
  padding-left: 6.3em; }
  .shapes h3 {
    padding-top: 1rem !important;
    font-size: 18px;
    font-weight: 600; }

.pricingTableTitle {
  font-family: 'GT America Expanded Regular';
  margin-top: 1em !important;
  font-size: 40px;
  letter-spacing: 0.21px;
  line-height: 50px;
  margin-bottom: 20px;
  text-align: center; }
  .pricingTableTitle .bold {
    font-family: 'GT America Expanded Bold';
    color: #2a2498; }
  @media (max-width: 575px) {
    .pricingTableTitle {
      margin-top: 2.5em;
      width: 100%;
      font-size: 26px;
      letter-spacing: 0.19px;
      line-height: 37px; } }

.pricingTableSubTitle {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.34px;
  line-height: 29px;
  text-align: center; }
  @media (max-width: 575px) {
    .pricingTableSubTitle {
      width: 100%;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.22px;
      line-height: 26px; } }

.signUpText {
  font-size: 28px;
  padding: 0.5em 1em 0 0;
  font-family: 'GT America Standard Bold';
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 44px; }

.select-a-plan {
  font-family: 'GT America Expanded Regular';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px; }

.submitBtn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin: auto 0;
  letter-spacing: 0.36px;
  line-height: 23px; }

.blueBackground {
  background-color: #f7faff; }

.pricingTable-company {
  padding-bottom: 20px; }
  .pricingTable-company h1 {
    margin: 0 auto 1rem; }
  .pricingTable-company h1,
  .pricingTable-company h2 {
    width: 120%;
    margin-left: -10%; }

.contactSales {
  font-family: 'GT America Light';
  font-size: 13px !important;
  text-decoration: underline;
  color: #2a2498;
  cursor: pointer; }

.table-styles .first-row {
  border-bottom: unset;
  height: auto; }

.table-styles tbody tr {
  height: 4.4em;
  border-bottom: unset; }

.table-styles td {
  font-family: 'GT America Standard Medium';
  vertical-align: middle;
  padding-right: 0; }

.table-styles td:first-child {
  padding-left: 2em;
  min-width: 400px; }

.table-styles .recommended {
  font-family: 'GT America Standard Medium';
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 3px solid #2a2498;
  border-radius: 1px;
  background-color: #2a2498;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px; }

.table-styles td:nth-child(2),
.table-styles td:nth-child(4) {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 199px;
  border-left: 1px solid #97add0;
  border-right: 1px solid #97add0;
  text-align: center;
  font-size: 12px; }

.table-styles tr:first-child td:nth-child(2),
.table-styles tr:first-child td:nth-child(4) {
  border-top: 1px solid #97add0; }

.table-styles tr:last-child td:nth-child(2),
.table-styles tr:last-child td:nth-child(4) {
  border-bottom: 1px solid #97add0; }

.table-styles td:nth-child(3) {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 241px;
  border-radius: 1px;
  background-color: #ffffff;
  border-left: 3px solid #2a2498;
  border-right: 3px solid #2a2498;
  color: #2a2498;
  text-align: center; }

.table-styles tr:first-child td:nth-child(3) {
  border-top: 3px solid #2a2498; }

.table-styles tr:last-child td:nth-child(3) {
  border-bottom: 3px solid #2a2498; }

.table-styles tr:nth-child(even):nth-child(n + 3) {
  background-color: #edf4ff; }

.table-styles tr:nth-child(n + 3) td:nth-child(1),
.table-styles tr:nth-child(n + 3) td:nth-child(2),
.table-styles tr:nth-child(n + 3) td:nth-child(4) {
  border-bottom: 1px solid #dbe4f3; }

.table-styles .growth,
.table-styles .enterprise {
  font-family: 'GT America Expanded Regular';
  font-size: 18px !important;
  line-height: 24px;
  letter-spacing: 0; }

.table-styles .premium {
  font-family: 'GT America Expanded Bold';
  color: #2a2498;
  font-size: 25px;
  line-height: 24px;
  letter-spacing: 0; }

.table-styles .growth-free-trial {
  font-family: 'GT America Standard Medium';
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #000000;
  border-radius: 21px;
  background-color: unset;
  color: #000000;
  font-weight: 900; }
  .table-styles .growth-free-trial:hover {
    background-color: #2a2498;
    border: none; }

.table-styles .premium-free-trial {
  font-family: 'GT America Standard Medium';
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 21px;
  color: #ffffff;
  background-color: #2a2498;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.28px;
  line-height: 23px; }
  .table-styles .premium-free-trial:hover {
    background-color: #19146e; }

.table-styles .enterprise-free-trial {
  font-family: 'GT America Standard Medium';
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #000000;
  border-radius: 21px;
  background-color: unset;
  color: #000000;
  font-weight: 900; }
  .table-styles .enterprise-free-trial:hover {
    background-color: #2a2498;
    border: none; }

.table-styles .growth-price {
  font-family: 'GT America Standard Bold';
  font-size: 26px !important; }
  .table-styles .growth-price span {
    font-family: 'GT America Standard Regular';
    font-size: 14px; }

.table-styles .premium-price {
  font-family: 'GT America Standard Bold';
  font-size: 34px; }
  .table-styles .premium-price span {
    font-family: 'GT America Standard Regular';
    font-size: 14px; }

.black-text {
  color: black !important; }
