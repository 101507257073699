.skill-section-image img {
  /*width: 420px;*/
  max-width: 100%;
  width: 101%;
  height: 190px; }
  @media (max-width: 991.98px) {
    .skill-section-image img {
      width: 100%;
      height: auto; } }

.img-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-top: 54%; }

.content-mask {
  position: absolute;
  top: -1%;
  left: 0;
  z-index: 2; }

.video-wrapper {
  position: relative;
  padding-bottom: 56.3%;
  /* 36.65%;*/
  height: 0;
  width: 100%;
  margin: auto; }

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.content-mask-image {
  position: absolute;
  top: -1%;
  left: 0;
  z-index: 1; }

.content-caption {
  position: absolute; }

.skills-headline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  height: 190px; }
  .skills-headline svg {
    height: 100%; }

@media (max-width: 991.98px) {
  .story-headline {
    font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 14px; } }

@media (max-width: 575.98px) {
  .skills-headline,
  .skill-section-image img {
    height: 90px; }
  .cta-container {
    margin-top: 8.5rem; } }
