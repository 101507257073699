.border-black {
  border: solid 1px #000000;
  border-radius: 0.3rem;
  padding: 3rem 0.3rem 6rem;
  min-height: 27em; }
  .border-black:hover {
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16); }

.white-card {
  background-color: #ffffff;
  color: #000000;
  border-radius: 0.3rem;
  padding: 3rem 0.3rem 6rem;
  /* box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);*/
  min-height: 27em; }
  .white-card .new {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    background-color: #2a2498;
    width: 60px;
    margin: 0 auto;
    padding: 0.3em 0.8em;
    border-radius: 0.3rem; }
    .white-card .new h3 {
      font-family: 'GT America Standard Medium';
      color: #ffffff;
      margin-bottom: 0; }
  .white-card:hover {
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16); }

.small-title {
  font-size: 20px; }

.cta-button {
  position: absolute;
  bottom: 30px;
  left: 0; }

.huge-margin-bottom {
  margin-bottom: 1rem !important; }

.more-padding {
  padding-top: 5em;
  padding-bottom: 5em; }

.feature-description {
  min-height: 4em !important; }
