@media (max-width: 575.98px) {
  .claims-new h1 {
    font-size: 1.2rem; } }

.help {
  font-size: 1.5rem;
  color: #000;
  vertical-align: text-top; }
  .help:hover {
    color: #0080d6; }

@media (max-width: 767.98px) {
  .hiddenSm {
    display: none !important; } }

@media (min-width: 768px) {
  .hiddenMd {
    display: none !important; } }

.githubSection {
  margin-top: 100px; }

.list-class {
  list-style: circle !important;
  margin-left: 10%; }

.submit-code {
  font-size: 20px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: lighter;
  cursor: pointer; }

@media (max-width: 560px) {
  .submit-code {
    font-size: 14px;
    font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: lighter;
    cursor: pointer; }
  .instruction {
    margin-top: 5%; }
  .tada {
    font-size: 16px; } }

.coding-language {
  padding: 8px; }

.email {
  padding: 10px; }

.tada {
  font-size: 50px; }

.take-an-assignment {
  background-color: inherit !important;
  border: 1px solid;
  outline: none; }
  .take-an-assignment:hover {
    border: 1px solid;
    color: black;
    background-color: #f5f5f5 !important; }

.ordered-list > li {
  padding: 6px; }
