/* TODO: will add global variable shadow color once merge  landing-page*/
.member-card {
  height: 360px;
  margin: 1rem;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  display: block;
  opacity: 0.8;
  text-decoration: none;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
  overflow-y: hidden;
  background: #fff;
  color: #000; }
  .member-card:hover {
    opacity: 1;
    text-decoration: none;
    -webkit-box-shadow: 0 10px 5px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 10px 5px 0 rgba(0, 0, 0, 0.2);
    color: #000; }
  @media (max-width: 575.98px) {
    .member-card {
      opacity: 1;
      height: 22rem; } }

@media (max-width: 575.98px) {
  .full-name-style {
    min-height: 4rem; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .full-name-style {
    min-height: 3rem; } }

.title-style {
  min-height: 3rem; }
  @media (max-width: 575.98px) {
    .title-style {
      min-height: 4rem; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .title-style {
      min-height: 3rem; } }

.member-card-small {
  height: 8rem !important; }

.member-card-body {
  padding: 1rem; }
  .member-card-body a {
    text-decoration: none; }
  .member-card-body ul li {
    margin-bottom: 0.15em; }
  .member-card-body ul h3,
  .member-card-body ul li {
    font-size: 1rem; }
    @media (max-width: 575.98px) {
      .member-card-body ul h3,
      .member-card-body ul li {
        font-size: 3.5vw; } }
  .member-card-body ul p {
    font-size: 0.85rem; }
    @media (max-width: 575.98px) {
      .member-card-body ul p {
        font-size: 3.5vw; } }

.avatar {
  width: 4rem;
  height: 4rem;
  line-height: 3.8rem; }

.responsive {
  padding: 0 1rem; }

.title {
  font-size: 2.3rem !important;
  line-height: 2.7rem; }
  @media (max-width: 560px) {
    .title {
      font-size: 1.4rem !important;
      line-height: 2rem; } }

.subtitle {
  font-size: 1.3rem !important;
  line-height: 1.7rem; }
  @media (max-width: 560px) {
    .subtitle {
      font-size: 1rem !important;
      line-height: 1.5rem; } }
