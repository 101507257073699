.table {
  margin-top: 1%; }

.card {
  border-radius: 10px;
  width: 99%;
  margin-bottom: 3%;
  padding: 15px !important; }

.cardOverflow {
  overflow-x: auto;
  max-width: 71.5em; }

.title {
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #5243a9;
  font-size: 20px;
  margin: 0 auto; }

.header {
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 3% 1% 1% 0%;
  font-size: 18px; }
