.fixed-height-mobile {
  height: 11em !important; }

.fixed-height-ipad {
  height: 7em !important; }

.fixed-height-desktop {
  height: 8em !important; }

.background {
  background-color: #f3f7f7;
  min-height: 161px;
  padding-top: 1.2em; }
