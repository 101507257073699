.hero {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: calc(100vh - 54px); }
  .hero h1 {
    margin: 0 auto 1rem; }
  .hero h1,
  .hero h2 {
    width: 120%; }

.lookBeyond {
  font-size: 25px; }

.hero-company {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: calc(100vh - 54px);
  padding-bottom: 20px; }
  .hero-company h1 {
    margin: 0 auto 1rem; }
  .hero-company h1,
  .hero-company h2 {
    width: 120%;
    margin-left: -10%; }

.topSection {
  background-color: #f3f3f3; }

.hero-content {
  position: absolute;
  top: 10em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%); }

.smaller-h2 {
  font-size: 1.3em !important;
  line-height: 1.4em !important;
  margin-top: 0px;
  margin-bottom: 0px; }

.expert-details-name {
  font-size: 1.4em !important;
  line-height: 1.8em !important;
  margin-top: 0px;
  margin-bottom: 0px; }
  @media (max-width: 560px) {
    .expert-details-name {
      font-size: 1em !important;
      line-height: 1.1em; } }

.roleImg {
  width: 5rem; }

.roleImgWrapper {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  border: 1px solid whitesmoke;
  background: whitesmoke; }

.border-black {
  border: solid 1px #000;
  border-radius: 5px;
  padding: 2em;
  margin-left: 1em;
  margin-right: 1em; }
  @media (max-width: 560px) {
    .border-black {
      padding: 1em; } }

.max-height-desktop {
  max-height: 840px; }

.margin-left-skill-grid {
  margin-left: 29px; }

.verified-icon-placeholder {
  width: 1.5em; }

.expert-details-mobile-placeholder {
  margin: 0 5em; }

.box-shadow {
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff; }
