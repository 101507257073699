.img-wrapper img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }
  .img-wrapper img:hover {
    -webkit-filter: none;
            filter: none; }

.overflow-hidden {
  overflow: hidden; }

.circle {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  font-size: 50px;
  color: #fff;
  line-height: 500px;
  text-align: center;
  background: gray; }

.slick-dots li button:before {
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  background-color: #000;
  border-radius: 50%; }

.slick-dots li.slick-active button:before {
  background-color: #000;
  opacity: 1; }

.slick-dots li.slick-non button:before {
  background-color: #b4b4b4 !important;
  opacity: 1; }

.huge-padding {
  padding-top: 7rem;
  padding-bottom: 7rem; }
  @media (max-width: 560px) {
    .huge-padding {
      padding-top: 4rem;
      padding-bottom: 4rem; } }

.testimonial-text {
  font-size: 1.7rem !important;
  line-height: 2.2rem; }
  @media (max-width: 575px) {
    .testimonial-text {
      font-size: 1rem !important; } }
  @media (max-width: 1199px) and (min-width: 576px) {
    .testimonial-text {
      font-size: 1.5rem !important; } }

.min-height {
  min-height: 100px; }
