/* TODO: will add global variable shadow color once merge  landing-page*/
.member-card {
  height: 25rem;
  margin: 1rem;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  display: block;
  opacity: 0.6;
  text-decoration: none;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
  overflow-y: hidden;
  background: #fff;
  color: #000; }
  .member-card:hover {
    text-decoration: none;
    opacity: 1;
    -webkit-box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
    color: #000; }
  @media (max-width: 560px) {
    .member-card {
      opacity: 1;
      height: 27rem; } }

.full-name-container {
  min-height: 4rem; }
  @media (max-width: 575.98px) {
    .full-name-container {
      min-height: 4rem; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .full-name-container {
      min-height: 3rem; } }

.full-name-text {
  font-size: 1.5rem;
  line-height: 2rem; }

.title-style {
  min-height: 3rem; }

.smaller-h2 {
  font-size: 1.4em !important;
  line-height: 1.2em !important; }
  @media (max-width: 560px) {
    .smaller-h2 {
      font-size: 1.2em !important;
      line-height: 1em !important; } }

.bigger-h1 {
  font-size: 2.8em !important;
  line-height: 2em !important; }
  @media (max-width: 560px) {
    .bigger-h1 {
      line-height: 1.2em !important; } }

.member-card-small {
  height: 8rem !important; }

.member-card-body a {
  text-decoration: none; }

.member-card-body ul li {
  margin-bottom: 0.15em; }

.member-card-body ul h3,
.member-card-body ul li {
  font-size: 1rem; }
  @media (max-width: 575.98px) {
    .member-card-body ul h3,
    .member-card-body ul li {
      font-size: 3.5vw; } }

.member-card-body ul p {
  font-size: 0.85rem; }
  @media (max-width: 575.98px) {
    .member-card-body ul p {
      font-size: 3.5vw; } }

.avatar {
  width: 4rem;
  height: 4rem;
  line-height: 3.8rem; }

.responsive {
  padding: 0 1rem; }

.img-placeholder {
  max-height: 8em;
  overflow: hidden; }

.box-shadow:hover {
  -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff; }
