.hero {
  position: relative;
  overflow: hidden;
  height: 100%;
  /* max-height: 700px;*/ }
  @media (min-width: 992px) {
    .hero {
      margin-bottom: 0px; } }

.title {
  max-width: 450px;
  color: #000000;
  font-family: 'GT America Expanded Medium';
  font-size: 36px;
  letter-spacing: 0;
  line-height: 48px; }
  @media (max-width: 600px) {
    .title {
      margin-left: 0.8rem; } }

.subtitle {
  max-width: 498px;
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 22px;
  letter-spacing: 0;
  line-height: 36px; }

.title-text {
  font-size: 39px !important;
  line-height: 55px !important; }
  @media (max-width: 575px) {
    .title-text {
      font-size: 2rem !important;
      line-height: 2.5rem !important; } }
  @media (max-width: 1199px) and (min-width: 576px) {
    .title-text {
      font-size: 2rem !important;
      line-height: 2.5rem !important; } }

.title-left-margin {
  margin-left: 3rem !important; }
  @media (max-width: 575px) {
    .title-left-margin {
      margin-left: 1rem !important; } }
