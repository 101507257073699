@media (min-width: 992px) {
  .benefit-section:last-of-type {
    padding-top: 0rem; } }

.header-title {
  line-height: 48px; }
  @media (max-width: 991.98px) {
    .header-title {
      line-height: 30px; } }

.header-sub-title {
  line-height: 30px; }
  @media (max-width: 991.98px) {
    .header-sub-title {
      line-height: 20px; } }

.form-field {
  margin-top: -40px; }

@media (max-width: 991.98px) {
  .benefit-item {
    -ms-flex-flow: column;
        flex-flow: column; }
    .benefit-item .feature-description {
      padding-left: 0;
      padding-top: 1.25rem;
      width: 90%; } }

@media (max-width: 575.98px) {
  .benefit-item {
    -ms-flex-flow: row;
        flex-flow: row;
    padding-bottom: 1.25rem; }
    .benefit-item .feature-description {
      padding-left: 33px;
      padding-top: 0;
      width: 80%; }
    .benefit-item:last-child {
      padding-bottom: 0; } }

.benefits-navigation {
  padding-left: 0; }

.icon-big {
  width: 20%; }

.icon-big svg {
  height: auto;
  stroke-width: 4px; }

.feature-description {
  width: 80%;
  padding-left: 33px; }

.nav-item {
  width: 130px;
  margin-right: 15px;
  cursor: pointer;
  opacity: 0.5; }

#reward {
  -webkit-transform: rotateX("-90");
          transform: rotateX("-90"); }

.nav-item-active {
  opacity: 1; }

.nav-item div svg {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  padding: 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.nav-item svg {
  stroke-width: 10px;
  opacity: 0; }

.nav-item p {
  text-align: center;
  font-size: 12px;
  line-height: 1.25em;
  margin-top: 1.25em; }

.nav-item-dot-active,
.nav-item-dot-inactive {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0.6em auto 0; }

.nav-item-dot-active {
  background-color: black; }

.nav-item-dot-inactive {
  background-color: none; }

.bg-color {
  background: -webkit-gradient(linear, left top, right top, from(#fce312), color-stop(#fbea0d), to(#f9fd01));
  background: -webkit-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: -o-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: linear-gradient(90deg, #fce312, #fbea0d, #f9fd01); }

.btn {
  margin-top: -7px; }

@media (max-width: 767.98px) {
  .icon-big {
    width: 20%;
    margin-bottom: 25px;
    padding-right: 0px; }
    .icon-big svg {
      width: 70%; }
  .icon-big svg {
    stroke-width: 4px; }
  .feature-description {
    padding-left: 1.5rem;
    width: 70%;
    padding-bottom: 1.5rem; }
  .feature-description:last-child {
    padding-bottom: 0; }
  .icon-big {
    padding-bottom: 0; }
  .nav-item {
    width: 33%;
    margin-top: 3rem; }
  .section-head {
    width: 100%; } }
