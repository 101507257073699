.explanation-typo-headline {
  width: 420px;
  max-width: 100%;
  height: 190px; }
  @media (max-width: 991.98px) {
    .explanation-typo-headline {
      width: 100%;
      height: auto; } }

.video-container {
  margin: auto; }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  max-width: 840px;
  margin: auto; }

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.explanation-headline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  height: 190px; }
  .explanation-headline svg {
    height: 100%; }

@media (max-width: 575.98px) {
  .explanation-headline,
  .explanation-typo-headline {
    height: 90px; } }
