.navbar .nav .nav-link {
  width: 98px;
  height: 100%; }

.navbar {
  min-height: 70px; }
  .navbar a {
    text-decoration: none; }
    .navbar a:hover {
      color: black;
      text-decoration: none; }
  .navbar .nav {
    /*position: absolute;*/
    height: 70px;
    /* width: 196px;*/
    /*right: 0;*/ }
    .navbar .nav .nav-link {
      display: inline-block;
      background-color: #f2f2f2; }
      .navbar .nav .nav-link.active span:before {
        bottom: 9px; }
      .navbar .nav .nav-link span {
        display: inline-block;
        /*padding-top: 1rem;*/
        padding-top: calc((54px / 2) - 0.5rem); }
      .navbar .nav .nav-link:hover {
        background-color: black;
        color: white; }
  .navbar .active {
    position: relative; }
    .navbar .active span:before {
      content: '';
      height: 5px;
      width: 5px;
      background-color: black;
      border-radius: 50%;
      opacity: 1;
      position: absolute;
      left: 48%;
      bottom: -10px; }

.nav-btn a:hover {
  color: white !important; }

.modal-title {
  font-family: 'GT America Expanded Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.bold-button {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
