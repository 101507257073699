.more-padding {
  padding-top: 5em;
  padding-bottom: 5em; }

.hiring {
  background-color: inherit !important;
  border: 1px solid;
  font-size: '16px'; }
  .hiring:hover {
    color: white;
    background-color: black !important;
    border: 1px solid black; }

.title {
  font-size: 42px !important;
  line-height: 52px !important; }
  @media (max-width: 575px) {
    .title {
      font-size: 22px !important;
      line-height: 28px !important; } }
