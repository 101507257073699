.page {
  height: 100% !important; }

.page-row {
  height: 100% !important; }

.tada-img {
  height: 213px;
  width: 231px;
  margin: 0 auto; }

.submitBtn {
  color: #ffffff;
  font-size: 30px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  padding: 0.5rem 3rem;
  border-radius: 38px; }

.heading {
  color: #000000;
  font-size: 80px;
  line-height: 100px;
  letter-spacing: 5px;
  font-weight: 900; }

.sub-title {
  color: #000000;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center; }
