.features {
  background: #fff;
  margin: 5rem auto; }
  @media (max-width: 575px) {
    .features {
      margin: 3rem auto; } }
  .features .heading {
    text-align: center; }
    .features .heading .title {
      color: #000000;
      font-family: 'GT America Extended Bold';
      font-size: 32px;
      letter-spacing: 0;
      line-height: 38px;
      text-align: center; }
      @media (max-width: 575px) {
        .features .heading .title {
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px; } }
    .features .heading .subTitle {
      color: #000000;
      font-family: 'GT America Standard Regular';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center; }
      @media (max-width: 575px) {
        .features .heading .subTitle {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 20px; } }
  .features .featureList {
    margin: 1.5rem auto; }
    .features .featureList .featureBox {
      width: 344px;
      margin: 0 3rem;
      text-align: center; }
      @media (max-width: 780px) {
        .features .featureList .featureBox {
          margin: 4rem auto; } }
      @media (max-width: 575px) {
        .features .featureList .featureBox {
          margin: 4rem auto; } }
      .features .featureList .featureBox img {
        margin: 0 auto; }
      .features .featureList .featureBox .info {
        /* height: 100px;*/
        text-align: center; }
        .features .featureList .featureBox .info h3 {
          color: #291d9b;
          font-family: 'GT America Standard Bold';
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px;
          text-align: center; }
          @media (max-width: 575px) {
            .features .featureList .featureBox .info h3 {
              font-size: 16px;
              letter-spacing: 0;
              line-height: 20px; } }
        .features .featureList .featureBox .info p {
          color: #000000;
          font-family: 'GT America Standard Regular';
          font-size: 15px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 22px;
          text-align: center; }
          @media (max-width: 575px) {
            .features .featureList .featureBox .info p {
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
              padding: 0 3rem; } }
