/*
	.classOne {
		background-color: #000;
	}
*/
.submitBtn {
  font-size: 20px;
  font-weight: 500;
  line-height: 31px;
  text-align: center;
  margin: auto 0; }

.submitBtnMobile {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin: auto 0; }

.pageTitle {
  font-family: 'GT America Expanded Medium';
  font-size: 38px;
  letter-spacing: 0px;
  line-height: 52px;
  text-align: center; }

.pageSubTitle {
  color: #464646;
  font-size: 24px;
  line-height: 36px;
  text-align: center; }

.hero-company {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 60em;
  padding-bottom: 20px; }
  .hero-company h1 {
    margin: 0 auto 1rem; }
  .hero-company h1,
  .hero-company h2 {
    width: 120%;
    margin-left: -10%; }

.hero-content-company {
  position: absolute;
  top: 0px;
  bottom: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }
