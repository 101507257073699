.header {
  background-color: white;
  /*padding: 0.5rem 1rem !important;*/
  padding: 0 !important;
  width: 100vw; }

.layout {
  position: relative;
  min-height: 100%;
  /* This allows inner content to be 100% */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around; }

.content {
  position: relative;
  padding-top: 3.3rem;
  /* Equal to height of Header NavBar*/
  height: 100%;
  width: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.flash-wrapper {
  position: fixed;
  width: 100%;
  z-index: 2000; }
