/* section layout */
.blue-background {
  padding: 3em 0 5em;
  background-color: #2a2498;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05); }

.white-btn {
  background-color: white;
  color: #000000;
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 23px;
  text-align: center; }
  .white-btn:hover {
    background-color: #e1e1e1;
    color: black; }

.title {
  font-family: 'GT America Expanded Bold';
  color: #ffffff;
  margin: 0 auto;
  font-size: 36px;
  letter-spacing: 0.24px;
  line-height: 48px;
  text-align: center; }
  @media (max-width: 767.98px) {
    .title {
      color: #ffffff;
      font-size: 28px;
      letter-spacing: 0.28px;
      line-height: 40px;
      margin: 0 auto;
      max-width: 13em;
      font-weight: 600; } }

.subtitle {
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
  padding: 1em 0;
  font-family: 'GT America Standard Regular';
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px; }
  @media (max-width: 767.98px) {
    .subtitle {
      color: #ffffff;
      text-align: center;
      margin: 0 auto;
      padding: 2em 0;
      width: 22em;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.22px;
      line-height: 26px; } }
