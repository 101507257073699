.border-black {
  border: solid 1px #000000;
  border-radius: 0.3rem;
  padding: 3rem 1rem 1rem; }
  .border-black:hover {
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    border-style: none; }

.small-title {
  font-size: 20px;
  margin-bottom: 0px !important; }

.cta-button {
  position: absolute;
  bottom: 30px;
  left: 0; }

.huge-margin-top {
  margin-top: 1rem !important; }

.more-padding {
  padding-top: 5em;
  padding-bottom: 5em; }

.feature-description {
  min-height: 4em !important; }
